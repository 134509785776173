import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { 
  JournalLargeResource,
  MasterEducationMasterGroupIdExercisesJournalGetParams,
  MasterEducationMasterGroupIdExercisesJournalGetRequest,
} from '@/store/types'

/**
 * Журнал отметок мастера. 
 * 
 */

@Module({
  dynamic: true,
  name: 'MasterJournal',
  namespaced: true,
  store,
})
class MasterJournal extends VuexModule {
  // ---------------------------- Master journal ---------------------------- >>
  // Current
  masterJournal: JournalLargeResource | null = null

  @Mutation
  setMasterJournal(payload: JournalLargeResource) {
    this.masterJournal = payload
  }

  @Action({ rawError: true })
  async fetchMasterJournal (payload: { masterGroupId: number, filter: MasterEducationMasterGroupIdExercisesJournalGetParams }) {
    const { data } = await MasterEducationMasterGroupIdExercisesJournalGetRequest(payload.masterGroupId, payload.filter)
    this.setMasterJournal(data)
    return data
  }
}

const MasterJournalModule = getModule(MasterJournal)

export default MasterJournalModule

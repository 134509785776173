













































































import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import ChartMaster from '@/components/charts/ChartMaster.vue'
import Hearts from '@/components/Hearts.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import MasterJournalModule from '@/store/modules/master/journal'
import { ExerciseStatus, JournalLargeExerciseResource } from '@/store/types'
import { declension, parseDateToMilliseconds } from '@/utils/functions'
import CurrentTimeMixin from '@/mixins/CurrentTimeMixin'
import MasterEducationModule from '@/store/modules/master/education'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    ChartMaster,
    Hearts,
    Select,
    TableFooter,
    Tag,
  },
})
export default class Journal extends Mixins(MasterGroupMixin, NotifyMixin, CurrentTimeMixin) {
  private monthId: number | null = null

  private get headers() {
    return [
      { cellClass: 'cell-width-15', text: 'Тип', value: 'type' },
      { cellClass: 'cell-width-50', text: 'Задание', value: 'exercise' },
      { cellClass: 'cell-width-15', text: 'Оценка', value: 'mark' },
      { cellClass: 'cell-width-15', text: 'Дата сдачи', value: 'spendingAt' },
      { cellClass: 'cell-width-5', text: '', value: 'heartStatus' },
    ]
  }

  private mounted() {
    if (this.currentMasterGroupMonthID) {
      this.monthId = this.currentMasterGroupMonthID as number
    } else {
      this.fetchMasterJournal()
    }
  }

  private get masterJournal() {
    return MasterJournalModule.masterJournal
  }

  private get exercisesText() {
    if (this.masterJournal) {
      return declension(this.masterJournal.totalCount, ['задание', 'задания', 'заданий'])
    }
    return ''
  }

  private get maxText() {
    if (this.masterJournal) {
      return declension(this.masterJournal.points, ['балл', 'балла', 'баллов'])
    }
    return ''
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private fetchMasterJournal() {
    if (!this.monthId && this.currentMasterGroupMonth) {
      this.monthId = +this.currentMasterGroupMonth.value
    }

    MasterJournalModule.fetchMasterJournal({ filter: { monthId: this.monthId as number }, masterGroupId: this.currentMasterGroupID })
      .catch(this.notifyError)
  }

  private determinateColor (item: JournalLargeExerciseResource) {
    if (parseDateToMilliseconds(item.deadlineAt) > this.currentTime && item.lostHeart) {
      return 'red'
    } else if (ExerciseStatus.COMPLETE === item.status.value || ExerciseStatus.CHECKED === item.status.value) {
      return 'teal'
    }
    return 'orange'
  }

  private determinateHeartStatus (item: JournalLargeExerciseResource) {
    return (item.status.value as ExerciseStatus) === ExerciseStatus.COMPLETE
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Журнал оценок',
    }
  }

  @Watch('monthId')
  watchMonthId (value: number) {
    MasterEducationModule.setSavedMasterGroupMounthID(value)
    this.fetchMasterJournal()
  }

  @Watch('currentMasterGroupID')
  watchCurrentMasterGroupID () {
    if (this.currentMasterGroupMonth) {
      this.monthId = this.currentMasterGroupMonth.value as number
    } else {
      this.fetchMasterJournal()
    }
  }
}
